import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import question from "./modules/question";
import category from "./modules/category";
import answer from "./modules/answer";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    clearError({ commit }) {
      commit("clearError");
    },
  },
  getters: {
    error: (state) => state.error,
  },
  modules: {
    user,
    question,
    category,
    answer,
  },
  plugins: [createPersistedState()],
});

import Vue from "vue";
import VueRouter from "vue-router";
import routeNames from "./routeNames";

Vue.use(VueRouter);

const routes = [
  {
    path: routeNames.home,
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: routeNames.selection,
    name: "Selection",
    component: () => import("../views/Selection.vue"),
    meta: { transitionName: "slide", auth: true },
  },
  {
    path: routeNames.question,
    name: "Question",
    component: () => import("../views/Question.vue"),
    meta: { transitionName: "slide", auth: true },
  },
  {
    path: routeNames.final,
    name: "Final",
    component: () => import("../views/Final.vue"),
    meta: { transitionName: "slide", auth: true },
  },
  {
    path: routeNames.leaderboard,
    name: "Leaderboard",
    component: () => import("../views/Leaderboard.vue"),
    meta: { transitionName: "slide" },
  },
  {
    path: routeNames.admin,
    name: "Admin",
    component: () => import("../views/admin/Admin"),
    meta: { transitionName: "slide" },
  },
  {
    path: routeNames.shrink,
    name: "Shrink",
    component: () => import("../views/Shrink"),
    meta: { transitionName: "slide" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userId = localStorage.getItem("userId");
  const requiredAuth = to.matched.some((record) => record.meta.auth);
  if (!requiredAuth && userId && to.path === routeNames.home) {
    next(routeNames.selection);
  } else if (requiredAuth && !userId) {
    next(routeNames.home);
  } else {
    next();
  }
});
export default router;

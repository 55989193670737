import createApiConfig from "@/api/config";
import baseUrls from "@/api/baseUrls";

const apiService = createApiConfig(baseUrls.SERVER);

export default class UserApi {
  static async validateUser(store) {
    const response = await apiService.get(`/validate-user/${store}`);
    return response.data;
  }

  static async getShrinkByStore(store) {
    const response = await apiService.get(`/get-shrink/${store}`);
    return response.data;
  }
}

import categories from "@/data/categories";

export default {
  state: {
    categories,
    currentCategory: {},
    chosenCategoryIndexes: [],
  },
  mutations: {
    setCurrentCategory: (state, payload) => {
      state.currentCategory = { ...payload };
    },
    setCategoryIndex: (state, payload) => {
      state.chosenCategoryIndexes.push(payload);
    },
    resetChosenCategoryIndexes: (state) => {
      state.chosenCategoryIndexes = [];
    },
  },
  actions: {
    setCurrentCategory({ commit }, category) {
      commit("setCurrentCategory", category);
    },
    setCategoryIndex({ commit }, categoryIndex) {
      commit("setCategoryIndex", categoryIndex);
    },
    resetChosenCategoryIndexes({ commit }) {
      commit("resetChosenCategoryIndexes");
    },
  },
  getters: {
    categories: (state) => state.categories,
    currentCategory: (state) => state.currentCategory,
    chosenCategoryIndexes: (state) => state.chosenCategoryIndexes,
  },
};

import questions from "@/data/questions";

export default {
  state: {
    questions: questions,
    currentQuestion: {},
  },
  mutations: {
    setCurrentQuestion: (state, payload) => {
      state.currentQuestion = { ...payload };
    },
  },
  actions: {
    setCurrentQuestion({ commit }, question) {
      commit("setCurrentQuestion", question);
    },
  },
  getters: {
    questions: (state) => state.questions,
    currentQuestion: (state) => state.currentQuestion,
  },
};

import UserApi from "@/api/user.api";

export default {
  state: {
    user: {
      name: "",
      score: 0,
    },
  },
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    increaseScore: (state, payload) => {
      state.user.score += +payload;
    },
    resetUser(state) {
      state.user = {};
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    increaseScore({ commit }, value) {
      commit("increaseScore", value);
    },
    resetUser({ commit }) {
      commit("resetUser");
    },
    async validateUser({ commit }, store) {
      try {
        const response = await UserApi.validateUser(store);
        return response.data;
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
    async getShrinkByStore({ commit }, store) {
      try {
        const response = await UserApi.getShrinkByStore(store);
        return response.data;
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
  },
};

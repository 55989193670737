import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import digitFilter from "@/filters/digit.filter";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.filter("digit", digitFilter);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

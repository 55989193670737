import AnswerApi from "@/api/answer.api";

export default {
  state: {
    answers: [],
    openGuessAnswer: "",
    openGuessAnswers: [],
  },
  mutations: {
    setOpenGuessAnswer: (state, payload) => {
      state.openGuessAnswer = payload;
    },
    clearOpenGuessAnswer: (state) => {
      state.openGuessAnswer = "";
    },
    setOpenGuessAnswers: (state, payload) => {
      state.openGuessAnswers = [...payload];
    },
    clearOpenGuessAnswers: (state) => {
      state.openGuessAnswers = [];
    },
    setAnswer: (state, payload) => {
      state.answers.push(payload);
    },
    resetAnswers: (state) => {
      state.answers = [];
    },
  },
  actions: {
    setOpenGuessAnswer({ commit }, answer) {
      commit("setOpenGuessAnswer", answer);
    },
    clearOpenGuessAnswer({ commit }) {
      commit("clearOpenGuessAnswer");
    },
    setAnswer({ commit }, answer) {
      commit("setAnswer", answer);
    },
    resetAnswers({ commit }) {
      commit("resetAnswers");
    },
    async addOpenGuessAnswer({ commit }, { userId, answer, name }) {
      try {
        const response = await AnswerApi.addAnswer({ userId, answer, name });
        return response.data;
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
    async getOpenGuessAnswers({ commit }) {
      try {
        const response = await AnswerApi.getAnswers();
        commit("setOpenGuessAnswers", response.data);
        return response.data;
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
    async clearOpenGuessAnswers({ commit }) {
      try {
        const response = await AnswerApi.clearAnswers();
        commit("clearOpenGuessAnswers");
        return response;
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
    async getReport({ commit }) {
      try {
        return await AnswerApi.getReport();
      } catch (error) {
        commit("setError", error);
        throw error;
      }
    },
  },
  getters: {
    answers: (state) => state.answers,
    openGuessAnswer: (state) => state.openGuessAnswer,
    openGuessAnswers: (state) => state.openGuessAnswers,
  },
};

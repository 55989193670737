<template>
  <div id="app">
    <transition-page>
      <router-view />
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from "@/components/TransitionPage";

export default {
  name: "App",
  components: {
    TransitionPage,
  },
};
</script>

<style>
@import "assets/vendor/reset.css";

html {
  font-size: 62.5%;
}

body {
  font-family: "RosarioRegular", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.2;

  color: #ffffff;

  overflow: hidden;
}

#app {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;

  background-image: url("assets/images/shrink-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* FONTS */

@font-face {
  font-family: "RosarioRegular";
  src: local("RosarioRegular");
  src: url("./assets/fonts/Rosario-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "RosarioBold";
  src: local("RosarioBold");
  src: url("./assets/fonts/Rosario-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular");
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold");
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

/* MEDIA QUERIES */
@media (max-width: 1440px) {
  html {
    font-size: 8px;
  }
}
</style>

import createApiConfig from "@/api/config";
import baseUrls from "@/api/baseUrls";

const apiService = createApiConfig(baseUrls.SERVER);

export default class AnswerApi {
  static async addAnswer({ userId, answer, name }) {
    const response = await apiService.post("/add-answer", {
      userId,
      answer,
      name,
    });
    return response.data;
  }

  static async getAnswers() {
    const response = await apiService.get("/get-answers");
    return response.data;
  }

  static async clearAnswers() {
    const response = await apiService.get("/clear-answers");
    return response.data;
  }

  static async getReport() {
    const response = await apiService.get("/report");
    return response.data;
  }
}

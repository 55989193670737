import axios from "axios";

const createApiConfig = (domain) => {
  const config = {
    baseURL: domain,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params: {},
  };
  return axios.create(config);
};

export default createApiConfig;

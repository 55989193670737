export default [
  {
    id: 1,
    question:
      "Where can associates find training on the cull markdown process? Choose all that apply.",
    type: "checkbox",
    choices: {
      a: "Pocket Guide",
      b: "The Same Page",
      c: "Yammer",
      d: "myApron Asset Protection Training Videos",
    },
    rightAnswer: ["a", "d"],
    categoryId: 1,
  },
  {
    id: 2,
    question:
      "What was the overall company pass <span>%</span> for Accuracy Cart in FY<span>21</span>?",
    type: "radio",
    choices: {
      a: `<span>84%</span>`,
      b: "<span>93%</span>",
      c: "<span>78%</span>",
      d: "<span>90%</span>",
    },
    rightAnswer: ["a"],
    categoryId: 5,
  },
  {
    id: 3,
    question:
      "In FY<span>21</span>, how much clearance inventory did the average store count during physical inventory?",
    type: "radio",
    choices: {
      a: "<span>$230К</span>",
      b: "<span>$159К</span>",
      c: "<span>$76К</span>",
      d: "<span>$310К</span>",
    },
    rightAnswer: ["b"],
    categoryId: 2,
  },
  {
    id: 4,
    question:
      "At what threshold does a price change require a cycle count by MET?",
    type: "radio",
    choices: {
      a: "<span>>$100</span>",
      b: "<span>>$150</span>",
      c: "<span>>$250</span>",
      d: "<span>>$275</span>",
    },
    rightAnswer: ["c"],
    categoryId: 3,
  },
  {
    id: 5,
    question:
      "Approximately what <span>%</span> of product is scanned at the register vs. hand-keyed?",
    type: "radio",
    choices: {
      a: "<span>97%</span>",
      b: "<span>90%</span>",
      c: "<span>95%</span>",
      d: "<span>99%</span>",
    },
    rightAnswer: ["a"],
    categoryId: 4,
  },
  {
    id: 6,
    question:
      "What were the total operational shrink dollars per minute in FY<span>21</span>? The closest guess wins a prize!",
    type: "radio",
    choices: {},
    rightAnswer: [""],
    categoryId: 6,
  },
];

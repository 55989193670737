function roundValuesBy(value, accuracy) {
  return (Math.round(value / accuracy) * accuracy)?.toString();
}

export default function digitFilter(value = "", del) {
  const stringVal = value?.toString();
  if (del) {
    return stringVal?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return stringVal?.length >= 7
    ? `${roundValuesBy(value, 10e5).slice(0, stringVal.length - 6)}M`
    : `${roundValuesBy(value, 10e3).slice(0, stringVal.length - 3)}K`;
}

export default [
  {
    id: 1,
    category: "Markdown Management",
    color: "#0071BC",
    gradient: "linear-gradient(360deg, #0071BC -32.27%, #2BC4F3 121.36%)",
  },
  {
    id: 2,
    category: "Price Change Execution",
    color: "#026430",
    gradient: "linear-gradient(0deg, #0A9743 0%, #56B943 121.36%)",
  },
  {
    id: 3,
    category: "Price Change Execution",
    color: "#FBB03B",
    gradient: "linear-gradient(0deg, #FFCA05 0%, #FFE7A3 121.36%)",
  },
  {
    id: 4,
    category: "Front End Accuracy",
    color: "#F15A24",
    gradient: "linear-gradient(0deg, #F4722B 0%, #FBAB3A 121.36%)",
  },
  {
    id: 5,
    category: "Front End Accuracy ",
    color: "#A5030A",
    gradient: "linear-gradient(0deg, #ED282B 0%, #F15F4C 121.36%)",
  },
  {
    id: 6,
    category: "Open Guess",
    color: "#84005D",
    gradient: "linear-gradient(0deg, #84005D 0%, #BB019D 121.36%)",
  },
];
